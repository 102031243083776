import {createActions} from "redux-actions";

const {
    getMarshrutlar,
    getMarshrutlarSuccess,
    getMarshrutlarFailure,
    getMarshrutlarIdle
} = createActions(
    "GET_MARSHRUTLAR",
    "GET_MARSHRUTLAR_SUCCESS",
    "GET_MARSHRUTLAR_FAILURE",
    "GET_MARSHRUTLAR_IDLE",
);


export {
    getMarshrutlar,
    getMarshrutlarSuccess,
    getMarshrutlarFailure,
    getMarshrutlarIdle
};
