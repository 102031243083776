import './post.scss';

import React from "react";

import { Link } from 'react-router-dom';

import iconHeartOutline from '../../assets/images/icon_heart-white-outline.svg';
import iconHeartFill from '../../assets/images/icon_heart-white-fill.svg';

const PostLocation = ({
    reverse,
    previewUrl,
    title,
    isFavorite,
    link
}) => {
    return (
        <Link to={link} className={`post _location ${reverse && '_reverse' || ''}`}>
            <div className='post__title'>{title}</div>
            <div className='post__preview'>
                <img src={previewUrl} />
                {/* <div className='post__favorite'>
                    {isFavorite && <img src={iconHeartOutline} /> || <img src={iconHeartFill} />}
                </div> */}
            </div>
        </Link>
    )
}

export default PostLocation;