import PostImage1 from '../../assets/images/post-1.jpg';

const data = [
    {
        id: 1,
        title: 'Test 1',
        previewUrl: PostImage1,
        isFavorite: false
    },
    {
        id: 2,
        title: 'Result 1',
        previewUrl: PostImage1,
        isFavorite: true
    },
    {
        id: 3,
        title: 'Result 2',
        previewUrl: PostImage1,
        isFavorite: false
    },
    {
        id: 4,
        title: 'Location 1',
        previewUrl: PostImage1,
        isFavorite: true
    },
    {
        id: 5,
        title: 'Test 2',
        previewUrl: PostImage1,
        isFavorite: false
    },
    {
        id: 6,
        title: 'Result 3',
        previewUrl: PostImage1,
        isFavorite: true
    }
]

export { data };