import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getCities,
    getCitiesSuccess,
    getCitiesFailure,
    getCitiesIdle
} from "./actions";

const status_cities = handleActions(
    {
        [getCitiesSuccess]: () => "SUCCESS",
        [getCities]: () => "LOADING",
        [getCitiesFailure]: () => "FAILURE",
        [getCitiesIdle]: () => "IDLE",
    },
    'IDLE'
);

const cities = handleActions(
    {
        [getCitiesSuccess]: (state, action) => action.payload.cities,
    },
    []
)

const homepage = combineReducers({
    status_cities,
    cities
});

export {homepage};
