import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getLocation,
    getLocationSuccess,
    getLocationFailure,
    getLocationIdle
} from "./actions";

const status_location = handleActions(
    {
        [getLocationSuccess]: () => "SUCCESS",
        [getLocation]: () => "LOADING",
        [getLocationFailure]: () => "FAILURE",
        [getLocationIdle]: () => "IDLE",
    },
    'IDLE'
);

const data = handleActions(
    {
        [getLocationSuccess]: (state, action) => action.payload.data,
    },
    {}
)

const location = combineReducers({
    status_location,
    data
});

export {location};
