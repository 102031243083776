import './erier.scss';

import React, { useState, useEffect } from 'react';

import Header from '../../layout/header';
import Map from '../../layout/map';

import { marshrut, routes_data } from './data';
import Player from '../../layout/player';

import AudioUrl from '../../assets/audio/qarasubazartashhan.mp3';
import PostLocation from '../../components/post/post_location';

import { useDispatch, useSelector } from 'react-redux';
import { getCity, getCityIdle } from '../../modules/erier/actions';

import { useParams } from 'react-router-dom';
import Node from '../../components/node';
import Lugat from '../../sections/lugat';

const Erier = () => {

    const params = useParams();

    const { cityID } = params;

    const [fullContent, setFullContent] = useState(false);

    const [data, setData] = useState(null);

    const dispatch = useDispatch();

    const status_city = useSelector((state) => state.city.status_city);
    const state_city = useSelector((state) => state.city.data);

    useEffect(() => {
        if(status_city == 'IDLE'){
            dispatch(getCity({
                id: cityID
            }));
        }
    }, [cityID])

    useEffect(() => {
        if(status_city == 'SUCCESS'){
            dispatch(getCityIdle());
            setData(state_city);
        }
    }, [status_city])

    return (
        <>
            <Header title={data && data.title} />
            <div className='main'>
                <div className='erier'>
                    <div className='erier__map'>
                        {data && data.routes && (
                            <Map data={data.routes} />
                        )}
                    </div>
                    <div className='erier__audio'>
                        {data && data.audio && (
                            <Player 
                                src={data.audio} 
                                // infoStyle={'_vertical'}
                                info={[data && data.info_short]}
                            />
                        )}
                    </div>
                    <div className='erier__content'>
                        <div className={`erier__content-wrap ${fullContent && '_full' || ''}`}>
                            {data && data.content && data.content.length > 0 && data.content.map((node, index) => {
                                return (
                                    <Node key={index} {...node} />
                                )
                            })}
                            {/* <p>Aqmescitniñ tüz yollarınen kezingende, aşıqqan maşinalarnı közetkende, tükânlarnıñ renkli vitrinalarnı devam...</p>
                            <p>Aqmescitniñ tüz yollarınen kezingende, aşıqqan maşinalarnı közetkende, tükânlarnıñ renkli vitrinalarnı devam...</p>
                            <p>Aqmescitniñ tüz yollarınen kezingende, aşıqqan maşinalarnı közetkende, tükânlarnıñ renkli vitrinalarnı devam...</p>
                            <p>Aqmescitniñ tüz yollarınen kezingende, aşıqqan maşinalarnı közetkende, tükânlarnıñ renkli vitrinalarnı devam...</p>
                            <p>Aqmescitniñ tüz yollarınen kezingende, aşıqqan maşinalarnı közetkende, tükânlarnıñ renkli vitrinalarnı devam...</p> */}
                        </div>                        
                        <div className='erier__content-more'>
                            <button className='button' onClick={e => setFullContent(!fullContent)}>{!fullContent && 'Devam' || 'Qısqa'}</button>
                        </div>
                    </div>
                    {data && data.lugat && data.lugat.length > 0 && (
                        <div className='erier__lugat'>
                            <Lugat data={data.lugat} />
                        </div>
                    )}
                    <div className='erier__location'>
                        {data && data.list && data.list.length > 0 && data.list.map((location, location_index) => {
                            let reverse = false;
                            if(( location_index + 1 ) % 2 == 0){
                                reverse = true;
                            }
                            return (
                                <React.Fragment key={location_index}>
                                    <PostLocation
                                        link={'/marshrutlar/marshrut/'+location.id}
                                        reverse={reverse}
                                        previewUrl={location.previewUrl}
                                        title={location.title}
                                        isFavorite={location.isFavorite}
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Erier;