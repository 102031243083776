import './lugat.scss';

import React, { useState } from "react";

import IconCloseAcc from '../../assets/images/icon-close-acc.svg';
import IconOpenAcc from '../../assets/images/icon-open-acc.svg';
import Node from '../../components/node';

const LugatItem = (props) => {

    const [showContent, setShowContent] = useState(false);
    
    return (
        <div className="acc__item">
            <button className={`acc__item-header ${showContent && '_active' || ''}`} onClick={e => {
                setShowContent(!showContent);
            }}>
                {props.Title}
                {showContent && (
                    <img src={IconCloseAcc} />
                ) || (
                    <img src={IconOpenAcc} />
                )}
            </button>
            {showContent && (
                <div className="acc__content">
                    {props.Text && props.Text.map((text) => {
                        return (
                            <Node {...text} />
                        )
                    })}
                </div>
            )}
        </div>
    )
}

const Lugat = ({
    data
}) => {

    const [showContent, setShowContent] = useState(false);

    return (
        <div className="acc">
            <button className={`acc__header ${showContent && '_active' || ''}`} onClick={e => {
                setShowContent(!showContent);
            }}>
                Luğat
                {showContent && (
                    <img src={IconCloseAcc} />
                )}
            </button>
            {showContent && (
                <>
                    {data && data.length > 0 && (
                        <>
                            {data.length > 1 && (
                                <div className="acc__list">
                                    {data.map((item) => {
                                        return (
                                            <LugatItem {...item} />
                                        )
                                    })}
                                </div>
                            )}
                            {data.length == 1 && (
                                <div className="acc__content">
                                    {data[0].Text && data[0].Text.map((text) => {
                                        return (
                                            <Node {...text} />
                                        )
                                    })}
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default Lugat;