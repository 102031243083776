import './location.css';

import React, { useEffect, useState } from 'react';

import Header from '../../layout/header';

import Player from '../../layout/player';

import AudioUrl from '../../assets/audio/qarasubazartashhan.mp3';

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PostImage1 from '../../assets/images/post-1.jpg';
import PostImage2 from '../../assets/images/post-2.jpg';

import iconClose from '../../assets/images/icon-close.svg';
import iconInfoImage from '../../assets/images/icon-info-image.svg';
import iconHeartOutline from '../../assets/images/icon_heart-white-outline.svg';
import iconHeartFill from '../../assets/images/icon_heart-white-fill.svg';
import iconMoreGallery from '../../assets/images/icon_more-gallery.svg'
import Gallery from '../../layout/gallery';
import { getLocation, getLocationIdle } from '../../modules/location/actions';
import Node from '../../components/node';
import Lugat from '../../sections/lugat';
import Map from '../../layout/map';

const LocationMap = ({
    routes,
    show,
    setShow,
    defaultCenter
}) => {
    console.log('defaultCenter', defaultCenter);
    return (
        <div className={`gallery ${show !== false && '_show' || ''}`}>
            <div className='gallery__buttons'>
                <button className='button' onClick={e => {
                    setShow(false)
                }}>
                    <img src={iconClose} />
                </button>
            </div>
            {routes && (
                <Map 
                    data={routes} 
                    defaultCenter={defaultCenter}                    
                />
            )}
        </div>
    )
}

const Location = () => {

    const [marker, setMarker] = useState({
        lat: null,
        lng: null
    })
    const [isFavorite, setIsFavorite] = useState(false);
    const [fullContent, setFullContent] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [showGallery, setShowGallery] = useState(false);

    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);

    const [gallery, setGallery] = useState([]);
    const [activeGallery, setActiveGallery] = useState(false);
    const [activeInfo, setActiveInfo] = useState(false);

    const params = useParams();

    const { locationID } = params;

    const [data, setData] = useState(null);

    const dispatch = useDispatch();

    const status_location = useSelector((state) => state.location.status_location);
    const state_location = useSelector((state) => state.location.data);

    useEffect(() => {
        if(!showGallery){
            setActiveGallery(false);
            setActiveInfo(false);
        }
    }, [showGallery])

    useEffect(() => {
        if(status_location == 'IDLE'){
            dispatch(getLocation({
                id: locationID
            }));
        }
    }, [locationID])

    useEffect(() => {
        if(status_location == 'SUCCESS'){
            dispatch(getLocationIdle());
            setData(state_location);
            setGallery(state_location.gallery);
        }
    }, [status_location])

    return (
        <>
            <Header title={data && data.title || ''} />
            <div className='main'>
                <div className='erier'>
                    <div className='erier__gallery'>
                        {/* <div className='erier__favorite'>
                            {isFavorite && <img src={iconHeartOutline} /> || <img src={iconHeartFill} />}
                        </div> */}
                        <Swiper
                            loop
                            className="erier__gallery-slider"
                            slidesPerView={1}
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            // onSlideChange={(e) => {
                            //     if(secondSwiper && secondSwiper.realIndex !== e.realIndex){
                            //         secondSwiper.slideToLoop(e.realIndex);
                            //     }
                            // }}
                            onSwiper={setFirstSwiper}
                        >
                            {gallery && gallery.length > 0 && gallery.length == 1 && gallery[0].gallery.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div onClick={e => {
                                            setShowGallery(true);

                                            secondSwiper.slideTo(index);

                                        }} className='erier__gallery-item'>
                                            <img src={item.link} />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                            {gallery && gallery.length > 0 && gallery.length > 1 && gallery.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div onClick={e => {
                                            setShowGallery(true);
                                            setActiveGallery(item.gallery);
                                            setActiveInfo(item.info);

                                            // secondSwiper.slideTo(index);

                                        }} className='erier__gallery-item'>
                                            <div className='erier__gallery-item-title'>
                                                {item.title}
                                                <img src={iconMoreGallery} />
                                            </div>
                                            <img src={item.gallery[0].link} />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className='erier__audio'>
                        {data && data.audio && (
                            <Player 
                                src={data.audio} 
                                infoStyle={'_vertical'}
                                info={data && data.info_short}
                                callbackClickInfo={e => {
                                    console.log('callbackClickInfo', e, data.info_short);
                                    // if(
                                    //     data.list_coordinates && 
                                    //     data.list_coordinates.length > 0
                                    // ){
                                        setShowMap(true);
                                        setMarker(e)
                                    // }
                                    // setDataMarker({

                                    // });
                                }}
                            />
                        )}
                    </div>
                    <div className='erier__content'>
                        <div className={`erier__content-wrap ${fullContent && '_full' || ''}`}>
                            {data && data.content && data.content.length > 0 && data.content.map((node, index) => {
                                return (
                                    <Node key={index} {...node} />
                                )
                            })}
                        </div>
                        <div className='erier__content-more'>
                            <button className='button' onClick={e => setFullContent(!fullContent)}>{!fullContent && 'Devam' || 'Qısqa'}</button>
                        </div>
                    </div>
                </div>
                {data && data.lugat && data.lugat.length > 0 && (
                    <Lugat data={data.lugat} />
                )}
            </div>
            {gallery && gallery.length > 0 && gallery.length == 1 && (
                <Gallery
                    show={showGallery}
                    setShow={setShowGallery}
                    setSecondSwiper={setSecondSwiper} 
                    firstSwiper={firstSwiper}
                    list={gallery[0].gallery}
                    info={gallery[0].info}
                />
            )} 
            {gallery && gallery.length > 1 && activeGallery && (
                <Gallery
                    show={showGallery}
                    setShow={setShowGallery}
                    setSecondSwiper={setSecondSwiper} 
                    firstSwiper={firstSwiper}
                    list={activeGallery}
                    info={activeInfo}
                />
            )}        
            {data && data.routes && data.routes.length > 0 && (
                <LocationMap 
                    routes={data.routes}
                    show={showMap}
                    setShow={setShowMap}
                    defaultCenter={marker}
                />
            )}
        </>
    )
}

export default Location;