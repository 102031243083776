import {createActions} from "redux-actions";

const {
    getLocation,
    getLocationSuccess,
    getLocationFailure,
    getLocationIdle
} = createActions(
    "GET_LOCATION",
    "GET_LOCATION_SUCCESS",
    "GET_LOCATION_FAILURE",
    "GET_LOCATION_IDLE",
);


export {
    getLocation,
    getLocationSuccess,
    getLocationFailure,
    getLocationIdle
};
