import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getHarita,
    getHaritaSuccess,
    getHaritaFailure,
    getHaritaIdle
} from "./actions";

const status_harita = handleActions(
    {
        [getHaritaSuccess]: () => "SUCCESS",
        [getHarita]: () => "LOADING",
        [getHaritaFailure]: () => "FAILURE",
        [getHaritaIdle]: () => "IDLE",
    },
    'IDLE'
);

const data = handleActions(
    {
        [getHaritaSuccess]: (state, action) => action.payload.data,
    },
    []
)

const harita = combineReducers({
    status_harita,
    data
});

export {harita};
