import { call, put, takeEvery, all } from "redux-saga/effects";

import { getReguest } from "../homepage/api";

import {
  getMarshrutlar,
  getMarshrutlarSuccess,
  getMarshrutlarFailure,
  getMarshrutlarIdle
} from "./actions";
import { domain } from "../../helpers/config";

function* getMarshrutlarSaga(action) {
  try {

    let fields_cities = 'fields[0]=Title'+
      '&fields[2]=Slug';

    let fields_locations = 'fields[0]=Title'+
      '&populate[0]=Preview'+
      '&populate[1]=City';

    const [
        cities,
        locations
    ] = yield all([
        call(getReguest, { route: '/api/cities?'+fields_cities+'&pagination[pageSize]=100' }),
        call(getReguest, { route: '/api/locations?'+fields_locations+'&pagination[pageSize]=100' }),
    ]);

    // console.log(cities,
    //   locations);

    let data = {};

    if(
      cities &&
      cities.data &&
      cities.data.length > 0
    ){
      cities.data.map((city) => {
        data = {
          ...data,
          [city.id]: {
            id: city.id,
            title: city.attributes.Title,
            list: []
          }
        }
      })
    }

    if(
      locations &&
      locations.data &&
      locations.data.length > 0
    ){
      locations.data.map((location) => {

        if(
          location &&
          location.attributes &&
          location.attributes.City &&
          location.attributes.City.data
        ){
          let preview = null;

          if(
            location.attributes.Preview &&
            location.attributes.Preview.data &&
            location.attributes.Preview.data.attributes &&
            location.attributes.Preview.data.attributes.url
          ){
            preview = domain + location.attributes.Preview.data.attributes.url;
          }

          let prevList = [];

          if(
            data[location.attributes.City.data.id] && 
            data[location.attributes.City.data.id].list
          ){
            prevList = data[location.attributes.City.data.id].list;
          }

          data[location.attributes.City.data.id] = {
            ...data[location.attributes.City.data.id],
            list: [
              ...prevList,
              {
                id: location.id,
                title: location.attributes.Title,
                previewUrl: preview,
                isFavorite: false
              }
            ]
          }
        }

      })
    }

    yield put(
      getMarshrutlarSuccess({
        data,
      })
    );

  } catch (error) {
    console.log(error);

    yield put(getMarshrutlarFailure(error));
  }
}

export function* getMarshrutlarWatcher() {
  yield takeEvery(getMarshrutlar.toString(), getMarshrutlarSaga);
}
