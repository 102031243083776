import { call, put, takeEvery, all } from "redux-saga/effects";

import { getReguest } from "../homepage/api";

import {
    getHarita,
    getHaritaSuccess,
    getHaritaFailure,
    getHaritaIdle
} from "./actions";
import { domain } from "../../helpers/config";

function* getHaritaSaga(action) {
  try {

    const { payload } = action;

    let fields_locations = 'fields[0]=Title'+
      '&populate[0]=Preview'+
      '&populate[1]=City' +
      '&populate[2]=Coordinates';

    const [
        locations
    ] = yield all([
        call(getReguest, { route: '/api/locations?'+fields_locations+'&pagination[pageSize]=100' }),
    ]);

    let routes = {};

    let data = [];

    if(
      locations &&
      locations.data &&
      locations.data.length > 0
    ){

      locations.data.map((location) => {

        let { attributes } = location;

        let { Coordinates, City, Title } = attributes;

        if(
          Coordinates &&
          Coordinates.length > 0
        ){
          Coordinates.map((item) => {

            console.log(item);

            if(item.Coordinate){

                let data_coordinate = item.Coordinate.replace(' ', '').split(',');
                let coordinate = {
                    lat: Number(data_coordinate[0]), 
                    lng: Number(data_coordinate[1]),
                    title: item.Title && item.Title !== '' && item.Title || Title
                };

                if(City && City.data && City.data.id){
                    if(routes[City.data.id]){
                        routes[City.data.id] = [
                            ...routes[City.data.id],
                            coordinate
                        ]
                    } else {
                        routes[City.data.id] = [coordinate]
                    }
                }

            }

          })
        }

      })

      console.log(routes);

      if(
        routes &&
        Object.values(routes) &&
        Object.values(routes).length > 0
      ){
        Object.values(routes).map((list_coordinates) => {

            let route = {
                origin: {},
                waypoint: [],
                destination: {}
            }
        
            if(
                list_coordinates &&
                list_coordinates.length > 0
            ){
        
                let first_coordinate = list_coordinates[0];
                let range_coordinate = list_coordinates.filter((item, index) => ( index > 0 && list_coordinates.length > index + 1)).map((item) => {
                    return {
                    location: {
                        ...item
                    },
                    stopover: true
                    }
                });
                let last_coordinate = list_coordinates[list_coordinates.length - 1];
        
                route = {
                    ...route,
                    origin: {
                        ...first_coordinate
                    },
                    destination: {
                        ...last_coordinate
                    }
                }
        
                if(list_coordinates.length > 2 && range_coordinate && range_coordinate.length > 0){
                    route = {
                        ...route,
                        waypoint: [
                            ...range_coordinate
                        ]
                    }
                }
        
            }
        
            data.push(route);

        })
      }
      
    }

    yield put(
        getHaritaSuccess({
        data: data
      })
    );

    // yield put(setActiveWarehouses(activeWarehouses))
  } catch (error) {
    console.log(error);

    yield put(getHaritaFailure(error));
  }
}

export function* getHaritaWatcher() {
  yield takeEvery(getHarita.toString(), getHaritaSaga);
}
