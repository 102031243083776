import {createActions} from "redux-actions";

const {
    getHarita,
    getHaritaSuccess,
    getHaritaFailure,
    getHaritaIdle
} = createActions(
    "GET_HARITA",
    "GET_HARITA_SUCCESS",
    "GET_HARITA_FAILURE",
    "GET_HARITA_IDLE",
);


export {
    getHarita,
    getHaritaSuccess,
    getHaritaFailure,
    getHaritaIdle
};
