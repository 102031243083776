import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getMarshrutlar,
    getMarshrutlarSuccess,
    getMarshrutlarFailure,
    getMarshrutlarIdle
} from "./actions";

const status_marshrutlar = handleActions(
    {
        [getMarshrutlarSuccess]: () => "SUCCESS",
        [getMarshrutlar]: () => "LOADING",
        [getMarshrutlarFailure]: () => "FAILURE",
        [getMarshrutlarIdle]: () => "IDLE",
    },
    'IDLE'
);

const data = handleActions(
    {
        [getMarshrutlarSuccess]: (state, action) => action.payload.data,
    },
    []
)

const marshrutlar = combineReducers({
    status_marshrutlar,
    data
});

export {marshrutlar};
