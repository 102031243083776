import './header.scss'

import React from "react";

import IconChevronLeft from '../../assets/images/icon-chevron-left.svg'; 
import { useNavigate } from 'react-router-dom';

const Header = ({
    title = 'YOLDAŞ',
    isInner = true,
    children
}) => {

    const navigate = useNavigate();

    return (
        <div className="header">
            {children && (
                <>{children}</>
            ) || (
                <>
                    {isInner && (
                        <div className='header__back'>
                            <button className='button' onClick={() => navigate(-1)}>
                                <img src={IconChevronLeft} alt={'YOLDAŞ'} />
                            </button>
                        </div>
                    )}
                    <div className='header__title'>{title}</div>
                </>
            )}
        </div>
    )
}

export default Header;