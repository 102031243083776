import './home.css'

import React, { useEffect, useState } from 'react';

import Header from '../../layout/header';
import TopLocations from '../../layout/top_locations/top_locations';

import { useDispatch, useSelector } from 'react-redux';
import { getCities } from '../../modules/homepage/actions';
import { domain } from '../../helpers/config';

import Logo from '../../assets/images/logo.svg'

const Home = ({
    isLoad, setIsLoad, isFirstLoad
}) => {

    const [cities, setCities] = useState([]);
    const [step, setStep] = useState(0);

    const dispatch = useDispatch();

    const status_cities = useSelector((state) => state.homepage.status_cities);
    const state_cities = useSelector((state) => state.homepage.cities);

    useEffect(() => {
        if(status_cities == 'IDLE'){
            dispatch(getCities());
        }
    }, [])

    useEffect(() => {
        if(status_cities == 'SUCCESS'){

            setCities(state_cities);
            setTimeout(function(){
                if(!isFirstLoad){
                    setIsLoad(true);
                } else {
                    setStep(1);
                }
            }, 3000);
        }
    }, [status_cities]);

    return (
        <>
            {!isLoad && (
                <div className='entry'>
                    {step == 0 && (
                        <div className='entry__step-1'>
                            <img src={Logo} />
                        </div>
                    )}
                    {isFirstLoad && (
                        <>
                            {step == 1 && (
                                <div className='entry__step-2'>
                                    <img src={Logo} />
                                    <p>
                                        Bu ilâvede qırımtatar halqınıñtarihiy abideleri aqqında esas malümatlarnı topladıq 
                                    </p>
                                    <button onClick={e => {
                                        setStep(2);
                                    }}>Devam</button>
                                </div>
                            )}
                            {step == 2 && (
                                <div className='entry__step-2'>
                                    <img src={Logo} />
                                    <p>
                                        İlâve ana tilinde yapılğan, mürekkep sözlerniñ ise tercimesini azırladıq
                                    </p>
                                    <button onClick={e => {
                                        setStep(3);
                                    }}>Devam</button>
                                </div>
                            )}
                            {step == 3 && (
                                <div className='entry__step-2'>
                                    <img src={Logo} />
                                    <p>
                                        Begengen seferni saylañız ve Qırım boylap seyahatnı başlañız!
                                    </p>
                                    <button onClick={e => {
                                        setStep(4);
                                        setIsLoad(true);
                                    }}>Başlayıq!</button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            <Header title={'YOLDAŞ'} isInner={false} />
            <div className='main'>
                {cities && cities.length > 0 && (
                    <TopLocations list={cities} />
                )}
            </div>
        </>
    )
}

export default Home;