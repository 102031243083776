import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getCity,
    getCitySuccess,
    getCityFailure,
    getCityIdle
} from "./actions";

const status_city = handleActions(
    {
        [getCitySuccess]: () => "SUCCESS",
        [getCity]: () => "LOADING",
        [getCityFailure]: () => "FAILURE",
        [getCityIdle]: () => "IDLE",
    },
    'IDLE'
);

const data = handleActions(
    {
        [getCitySuccess]: (state, action) => action.payload.data,
    },
    {}
)

const city = combineReducers({
    status_city,
    data
});

export {city};
