import { call, put, takeEvery, all } from "redux-saga/effects";

import { getReguest } from "../homepage/api";

import {
  getCity,
  getCitySuccess,
  getCityFailure,
  getCityIdle
} from "./actions";
import { domain } from "../../helpers/config";

function* getCitySaga(action) {
  try {

    const { payload } = action;

    const { id } = payload;

    let fields = 'fields[0]=Title'+
      '&fields[1]=Info_short'+
      '&fields[2]=Content' +
      '&populate[0]=Preview_homepage'+
      '&populate[1]=Audio'+
      '&populate[2]=Lugat';

    let fields_locations = 'fields[0]=Title'+
      '&populate[0]=Preview'+
      '&populate[1]=City' +
      '&populate[2]=Coordinates';

    const [
        city,
        locations_by_city
    ] = yield all([
        call(getReguest, { route: '/api/cities/'+id+'?'+fields+'&pagination[pageSize]=1' }),
        call(getReguest, { route: '/api/locations?'+fields_locations+'&filters[City][$eq]='+id+'&pagination[pageSize]=100' }),
    ]);

    let data = {
      lugat: []
    };

    if(
      city &&
      city.data &&
      city.data.attributes
    ){
      if(
        city.data.attributes.Title
      ){
        data = {
          ...data,
          title: city.data.attributes.Title
        }
      }
      if(
        city.data.attributes.Audio &&
        city.data.attributes.Audio.data &&
        city.data.attributes.Audio.data.attributes
      ){
        data = {
          ...data,
          audio: domain + city.data.attributes.Audio.data.attributes.url
        }
      }
      if(
        city.data.attributes.Content
      ){
        data = {
          ...data,
          content: city.data.attributes.Content
        }
      }
      if(
        city.data.attributes.Info_short
      ){
        data = {
          ...data,
          info_short: city.data.attributes.Info_short
        }
      }

      if(
        city.data.attributes.Lugat &&
        city.data.attributes.Lugat.length > 0
      ){

        data = {
          ...data,
          lugat: city.data.attributes.Lugat
        }

      }
    }

    if(
      locations_by_city &&
      locations_by_city.data &&
      locations_by_city.data.length > 0
    ){
      let list = [];

      let list_coordinates = [];

      locations_by_city.data.map((location) => {

        let { attributes } = location;

        let { Coordinates } = attributes;

        if(
          Coordinates &&
          Coordinates.length > 0
        ){
          Coordinates.map((item) => {

            if(item.Coordinate){
              let data_coordinate = item.Coordinate.replace(' ', '').split(',');
              list_coordinates.push({
                lat: Number(data_coordinate[0]), 
                lng: Number(data_coordinate[1]),
                title: item.Title || 'Marker'
              })
            }

          })
        }

      })

      let route = {
        origin: {
          // lat: 44.7495101, 
          // lng: 33.8470202,
          // title: 'marker 1'
        },
        waypoint: [
          // {
          //   location: {
          //     lat: 44.7491951, 
          //     lng: 33.8481756,
          //     title: 'marker 2'
          //   },
          //   stopover: true
          // }
        ],
        destination: {
          // lat: 44.7474755, 
          // lng: 33.8850030,
          // title: 'marker 20'
        }
      }

      console.log(list_coordinates);

      if(
        list_coordinates &&
        list_coordinates.length > 0
      ){

        let first_coordinate = list_coordinates[0];
        let range_coordinate = list_coordinates.filter((item, index) => ( index > 0 && list_coordinates.length > index + 1)).map((item) => {
          return {
            location: {
              ...item
            },
            stopover: true
          }
        });
        let last_coordinate = list_coordinates[list_coordinates.length - 1];

        route = {
          ...route,
          origin: {
            ...first_coordinate
          },
          destination: {
            ...last_coordinate
          }
        }

        console.log(range_coordinate);

        if(list_coordinates.length > 2 && range_coordinate && range_coordinate.length > 0){
          route = {
            ...route,
            waypoint: [
              ...range_coordinate
            ]
          }
        }

      }

      console.log(route);

      let routes = [
        route
      ];
      
      locations_by_city.data.map((location) => {

        let preview = null;

        if(
          location.attributes.Preview &&
          location.attributes.Preview.data &&
          location.attributes.Preview.data.attributes &&
          location.attributes.Preview.data.attributes.url
        ){
          preview = domain + location.attributes.Preview.data.attributes.url;
        }

        list.push({
          id: location.id,
          title: location.attributes.Title,
          previewUrl: preview,
          isFavorite: false
        })

      })
      data = {
        ...data,
        list: list,
        routes: routes
      }
    }

    yield put(
      getCitySuccess({
        data: data,
      })
    );

    // yield put(setActiveWarehouses(activeWarehouses))
  } catch (error) {
    console.log(error);

    yield put(getCityFailure(error));
  }
}

export function* getCityWatcher() {
  yield takeEvery(getCity.toString(), getCitySaga);
}
