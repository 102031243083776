import {createActions} from "redux-actions";

const {
    getCities,
    getCitiesSuccess,
    getCitiesFailure,
    getCitiesIdle
} = createActions(
    "GET_CITIES",
    "GET_CITIES_SUCCESS",
    "GET_CITIES_FAILURE",
    "GET_CITIES_IDLE",
);


export {
    getCities,
    getCitiesSuccess,
    getCitiesFailure,
    getCitiesIdle
};
