import './map.css';

import React, { useState, useCallback, useEffect, useRef } from 'react';

import { GoogleMap, LoadScript, DirectionsService, DirectionsRenderer, useJsApiLoader, InfoWindow, Marker, Polyline } from '@react-google-maps/api';

import blackMarker from '../../assets/images/icon-pin.svg'

import Header from '../header';
import { theme } from '../../helpers/theme-map';
import { routes_data_short, routes_data } from '../../pages/harita/data';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '16px',
  overflow: 'hidden'
};

const Map = ({ data, defaultCenter, defaultZoom }) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBBoGWyHzUWj1XU5bt9PAL-Nl8J1B6sY20"
    })

    const [infoWindow, setInfoWindow] = useState(null);
    const [map, setMap] = React.useState(null)
    const [center, setCenter] = useState({ lat: 44.957813, lng: 34.109547 });
    const [routes, setRoutes] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [directionsResponse, setDirectionsResponse] = useState([]);

    const mapRef = useRef(null);
    const [selectedMarker, setSelectedMarker] = useState(null);

    useEffect(() => {
        if(routes && routes.length > 0){
            let list = [];
            routes.map((route) => {
                list.push(route.origin);
                route.waypoint.map((waypoint) => {
                    list.push(waypoint.location);
                })
                list.push(route.destination);
            })
            setMarkers(list);
        }
    }, [routes]);
  
    const directionsCallback = (response, index, route) => {
        console.log(response, index, route);
        if(response !== null){
            if (response.status === 'OK' && response.routes && response.routes.length > 0) {
                if(response && response !== undefined && typeof response !== 'undefined'){
                    setDirectionsResponse(prev => {
                        const newResponses = [...prev];
                        newResponses[index] = response;
                        return newResponses;
                    });
                }
            } else {
                console.log('Directions request failed:', response.status);
                // Дополнительная обработка ошибок здесь
            }
        } else {
            console.log('Error occurred while fetching directions');
            // Дополнительная обработка ошибок здесь
        }
    };

    useEffect(() => {
        if (mapRef.current && directionsResponse.length === routes.length && routes.length == 1) {
            // console.log();
            const bounds = new window.google.maps.LatLngBounds();
            directionsResponse.forEach(response => {
                if(response.routes){
                    response.routes[0].overview_path.forEach(path => {
                        bounds.extend(path);
                    });
                }
            });
            mapRef.current.fitBounds(bounds);
    
            // Установка центра карты
            if (routes.length > 0) {
                const center = bounds.getCenter();
                mapRef.current.setCenter(center);
            }
        }
    }, [directionsResponse]);

    useEffect(() => {
        if(data){
            setRoutes(data);
            // setRoutes(routes_data);
        }
    }, [data])

    useEffect(() => {
        if(defaultCenter){
            console.log('defaultCenter', defaultCenter);
            setCenter(defaultCenter);
        }
    }, [defaultCenter])

    const handleClose = useCallback(() => setInfoWindow(false), []);

    return (
        <>
            {isLoaded && (
                <GoogleMap
                    onLoad={(map) => (mapRef.current = map)}
                    onUnmount={() => (mapRef.current = null)}
                    options={{
                        disableDefaultUI: true,
                        styles: theme
                    }}
                    center={center}
                    zoom={defaultCenter && defaultCenter.lat && defaultCenter.lng && 18 || 8}
                    mapContainerStyle={containerStyle}
                >
                    {routes.map((route, index) => {
                        return (
                            <>
                                {!directionsResponse.length && <DirectionsService
                                    key={index}
                                    options={{
                                        destination: route.destination,
                                        origin: route.origin,
                                        travelMode: 'WALKING',
                                        waypoints: route.waypoint
                                    }}
                                    callback={(response) => directionsCallback(response, index, route)}
                                />}
                            </>
                        )
                    })}
                    {directionsResponse && directionsResponse.length > 0 && directionsResponse.map((directionsResponse, index) => (
                        <DirectionsRenderer
                            key={index}
                            options={{
                                preserveViewport: true,
                                suppressMarkers: true,
                                directions: directionsResponse,
                                markerOptions: { 
                                    icon: blackMarker
                                }
                            }}
                        />
                    ))}
                    {markers && markers.length > 0 && markers.map((marker, index) => {
                        return (
                            <Marker
                                key={index}
                                position={marker}
                                icon={{
                                    url: blackMarker
                                }}
                                onClick={e => {
                                    setInfoWindow(marker);
                                }}
                            />
                        )
                    })}
                    {infoWindow && (
                        <InfoWindow 
                            onCloseClick={handleClose}
                            position={{
                                lat: infoWindow.lat,
                                lng: infoWindow.lng
                            }}
                        >
                            <div>{infoWindow.title && infoWindow.title || ''}</div>
                        </InfoWindow>
                    )}
                </GoogleMap>
            )}
        </>
    )
}

export default Map;