import './navbar.css';

import React from "react";

import { Link, NavLink } from 'react-router-dom';

import { navbar_menu } from '../../helpers/config'; 

const Navbar = ({
    setActiveCity
}) => {
    return (
        <div className="navbar">
            {navbar_menu.map((item, index) => {
                return (
                    <div className='navbar__item' key={index}>
                        <NavLink
                            to={item.link}
                            className={({ isActive, isPending }) => isPending ? "navbar__link _pending " + item.className : isActive ? "navbar__link _active " + item.className : "navbar__link " + item.className}
                        >
                            <item.icon />
                            {/* <img src={item.icon} alt={item.title} /> */}
                        </NavLink>
                    </div>
                )
            })}
        </div>
    )
}

export default Navbar;