import './gallery.css';

import React, { useState } from 'react';

import { Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import iconClose from '../../assets/images/icon-close.svg';
import iconInfoImage from '../../assets/images/icon-info-image.svg';
import Node from '../../components/node';

const Gallery = ({ show, setShow, list, setSecondSwiper, firstSwiper, info }) => {

    const [showInfo, setShowInfo] = useState(false);

    return (
        <div className={`gallery ${show !== false && '_show' || ''}`}>
            <div className='gallery__buttons'>
                {!showInfo && (
                    <button className='button' onClick={e => {
                        setShowInfo(true);
                    }}>
                        <img src={iconInfoImage} />
                    </button>
                )}
                <button className='button' onClick={e => {
                    if(showInfo){
                        setShowInfo(false);
                    } else {
                        setShow(false)
                    }
                }}>
                    <img src={iconClose} />
                </button>
            </div>
            <Swiper
                zoom
                loop
                className="gallery__slider"
                slidesPerView={1}
                modules={[Pagination, Zoom]}
                pagination={{ clickable: true }}
                onSwiper={setSecondSwiper}
                // onSlideChange={(e) => {
                //     if(firstSwiper && firstSwiper.realIndex !== e.realIndex){
                //         console.log(e);
                //         firstSwiper.slideToLoop(e.realIndex);
                //     }
                // }}
            >
                {list.map((item, index) => {
                    return (
                        <SwiperSlide zoom={true} key={index}>
                            <div className='gallery__slider-item'>
                                <img src={item.link} />
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            {showInfo && (
                <div className='gallery__info'>
                    {info && info.length > 0 && info.map((node) => {
                        return (
                            <Node {...node} />
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default Gallery;