import './qaldirmaq.css';

import React from 'react';
import Header from '../../layout/header';
import { data } from './data';
import PostLocation from '../../components/post/post_location';

const Qaldirmaq = () => {
    return (
        <>
            <Header title={'Qaldirmaq'} />
            <div className='main'>
                <div className='qaldirmaq'>
                    <div className='qaldirmaq__list'>
                        {data.map((location, location_index) => {
                            let reverse = false;
                            if(( location_index + 1 ) % 2 == 0){
                                reverse = true;
                            }
                            return (
                                <React.Fragment key={location_index}>
                                    <PostLocation
                                        link={'/marshrutlar/marshrut/location'}
                                        reverse={reverse}
                                        previewUrl={location.previewUrl}
                                        title={location.title}
                                        isFavorite={location.isFavorite}
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Qaldirmaq;