import './harita.css';

import React, { useState, useEffect } from 'react';

import Header from '../../layout/header';
import Map from '../../layout/map';
import { routes_data } from './data';

import { useSelector, useDispatch } from 'react-redux';
import { getHarita } from '../../modules/harita/actions';

const Harita = () => {

    const [routes_data, set_routes_data] = useState([]);

    const dispatch = useDispatch();

    const status_harita = useSelector((state) => state.harita.status_harita);
    const data = useSelector((state) => state.harita.data);

    useEffect(() => {
        if(status_harita == 'IDLE'){
            dispatch(getHarita());
        }
    }, [])

    useEffect(() => {
        if(status_harita == 'SUCCESS'){
            set_routes_data(data);
        }
    }, [status_harita])

    return (
        <>
            <Header title={'Harita'} />
            <div className='main'>
                <div className='map'>
                    <Map data={routes_data} />
                </div>
            </div>
        </>
    )
    
}

export default Harita;