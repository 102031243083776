import { fork } from "redux-saga/effects";
import { getHomepageWatcher } from './modules/homepage/saga';
import { getMarshrutlarWatcher } from './modules/marshrutlar/saga';
import { getCityWatcher } from './modules/erier/saga';
import { getHaritaWatcher } from "./modules/harita/saga";
import { getLocationWatcher } from "./modules/location/saga";
import { getSearchWatcher } from "./modules/search/saga";

export default function* rootSaga() {
  yield fork(getHomepageWatcher);
  yield fork(getMarshrutlarWatcher);
  yield fork(getCityWatcher);
  yield fork(getHaritaWatcher);
  yield fork(getLocationWatcher);
  yield fork(getSearchWatcher);
}
