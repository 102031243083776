import './player.scss'

import React, { useEffect, useRef, useState } from "react";

import { TailSpin } from 'react-loader-spinner'

import WaveSurfer from "wavesurfer.js";

import iconPlay from '../../assets/images/icon-play.svg'
import iconPause from '../../assets/images/icon-pause.svg'

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: "#D2D1CD",
  progressColor: "#0070BD",
//   cursorColor: "OrangeRed",
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 32,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true
});

const Player = ({ src, info, infoStyle, callbackClickInfo }) => {

  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isLoad, setIsLoad] = useState(false);
  const [duration, setDuration] = useState('')
  const [playing, setPlay] = useState(false);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    if(waveformRef){
        setPlay(false);

        const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);

        wavesurfer.current.load(src);

        wavesurfer.current.on('ready', function(){

            if(wavesurfer.current.decodedData){
                setIsLoad(true);
                setDuration((wavesurfer.current.decodedData.duration / 60).toFixed(2).replace('.',':'));
            }

        })
    }
    // return () => wavesurfer.current.destroy();
  }, [src]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div className='player'>
        <div className='player__top'>
            <div className='player__button'>
                {!isLoad && (
                  // <div className='player__loader'>
                    <TailSpin
                        visible={true}
                        height="30"
                        width="30"
                        color="#0070BD"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                  // </div>
                ) || (
                  <button onClick={handlePlayPause} className='button'>
                      {!playing && <img src={iconPlay} /> || <img src={iconPause} />}
                  </button>
                )}
            </div>
            <div className='player__wave' id="waveform" ref={waveformRef} />
            <div className='player__duration'>{duration}</div>
        </div>
        <div className={`player__bottom ${infoStyle}`}>
            {info && info.length > 0 && info.map((text, index) => {
                return (
                    <span key={index} onClick={e => {
                      if(callbackClickInfo){
                        let data_text = text.replaceAll(',','').split(' ');
                        callbackClickInfo({
                          lat: Number(data_text[0]),
                          lng: Number(data_text[1])
                        });
                      }
                    }}>{text}</span>
                )
            })}
        </div>
    </div>
  );
}

export default Player;