import './tapmaq.scss';

import React, { useState, useEffect } from 'react';
import Header from '../../layout/header';
import { ReactComponent as IconTapmaq } from '../../assets/images/icon-tapmaq.svg'; 
import { data } from './data';
import PostLocation from '../../components/post/post_location';

import { useDispatch, useSelector } from 'react-redux';
import { getSearch, getSearchIdle } from '../../modules/search/actions';

const Tapmaq = () => {

    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [results, setResults] = useState(null);

    const search_status = useSelector((state) => state.search.status_search);
    const search_data = useSelector((state) => state.search.data);

    const handleSearch = () => {

        if(search_status == 'IDLE' && search !== ''){
            dispatch(getSearch({
                value: search
            }))
        }
        // let response = data.filter(item => item.title.includes(search));
        // setResults(response);
    }

    useEffect(() => {
        if(search_status == 'SUCCESS'){
            dispatch(getSearchIdle());
            setResults(search_data);
        }
    }, [search_status])

    return (
        <>
            <Header title={'Qidirmaq'} />
            <div className='main'>
                <div className='tapmaq'>                    
                    <div className='tapmaq__form'>
                        <input 
                            className='input' 
                            placeholder='Yaziniz' 
                            value={search} 
                            onChange={e => {
                                setSearch(e.target.value);
                                setResults(null);
                            }} 
                        />
                        <button className='button' onClick={handleSearch}>
                            <IconTapmaq />
                        </button>
                    </div>
                    {results && search !== '' && (
                        <>
                            {results.length > 0 && (
                                <div className='tapmaq__results'>
                                    {results.map((location, location_index) => {
                                        let reverse = false;
                                        if(( location_index + 1 ) % 2 == 0){
                                            reverse = true;
                                        }
                                        return (
                                            <React.Fragment key={location_index}>
                                                <PostLocation
                                                    link={'/marshrutlar/marshrut/'+location.id}
                                                    reverse={reverse}
                                                    previewUrl={location.previewUrl}
                                                    title={location.title}
                                                    isFavorite={location.isFavorite}
                                                />
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            ) || (
                                <div className='tapmaq__not-found'>Tapmadiq...</div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default Tapmaq;