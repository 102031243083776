import { call, put, takeEvery, all } from "redux-saga/effects";

import { getReguest } from "../homepage/api";

import {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle
} from "./actions";
import { domain } from "../../helpers/config";

function* getSearchSaga(action) {
  try {

    const { payload } = action;

    const { value } = payload;
    
    let fields_locations = 'fields[0]=Title'+
      '&populate[0]=Preview';

    if (value && value !== '') {
        fields_locations =
        fields_locations +
            '&filters[$or][0][Keywords][$containsi]=' +
            value.toLowerCase();
    }

    const [
        locations_by_search
    ] = yield all([
        call(getReguest, { route: '/api/locations?'+fields_locations+'&pagination[limit]=100' }),
    ]);

    console.log('locations_by_search', locations_by_search);

    let data = [];

    if(
      locations_by_search &&
      locations_by_search.data &&
      locations_by_search.data.length > 0
    ){
        locations_by_search.data.map((item) => {

            let previewUrl = '',
                title = '';

            if(
                item.attributes &&
                item.attributes.Title
            ){
                title = item.attributes.Title;
            }

            if(
                item.attributes &&
                item.attributes.Preview &&
                item.attributes.Preview.data &&
                item.attributes.Preview.data.attributes &&
                item.attributes.Preview.data.attributes.url
            ){
                previewUrl = domain + item.attributes.Preview.data.attributes.url;
            }

            data.push({
                id: item.id,
                title: title,
                previewUrl: previewUrl,
                isFavorite: false
            })
        })
    //   data = locations_by_search.data;
    }

    yield put(
        getSearchSuccess({
            data: data,
        })
    );

    // yield put(setActiveWarehouses(activeWarehouses))
  } catch (error) {
    console.log(error);

    yield put(getSearchFailure(error));
  }
}

export function* getSearchWatcher() {
  yield takeEvery(getSearch.toString(), getSearchSaga);
}
