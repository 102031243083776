import { call, put, takeEvery, all } from "redux-saga/effects";

import { getReguest } from "./api";

import {
  getCities,
  getCitiesSuccess,
  getCitiesFailure,
  getCitiesIdle
} from "./actions";
import { domain } from "../../helpers/config";

function* getCitiesSaga(action) {
  try {

    let fields = 'fields[0]=Title'+
      '&fields[1]=Info_short'+
      '&fields[2]=Slug'+
      '&populate[0]=Preview_homepage';

    const [
        cities
    ] = yield all([
        call(getReguest, { route: '/api/cities?'+fields+'&pagination[pageSize]=100' }),
    ]);


    let list = [];
    if(cities && cities.data && cities.data.length > 0){
      cities.data.map((city) => {
            let { attributes, id } = city;
            let { Slug, Title, Info_short, Preview_homepage } = attributes;

            let preview = '';

            if(
                Preview_homepage &&
                Preview_homepage.data &&
                Preview_homepage.data.attributes &&
                Preview_homepage.data.attributes.url
            ){
                preview = domain + Preview_homepage.data.attributes.url;
            }

            list.push({
              id: id,
              preview: preview,
              title: Title,
              info: Info_short
            })
        })
    }

    yield put(
      getCitiesSuccess({
        cities: list,
      })
    );

    // yield put(setActiveWarehouses(activeWarehouses))
  } catch (error) {
    console.log(error);

    yield put(getCitiesFailure(error));
  }
}

export function* getHomepageWatcher() {
  yield takeEvery(getCities.toString(), getCitiesSaga);
}
