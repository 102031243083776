import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle
} from "./actions";

const status_search = handleActions(
    {
        [getSearchSuccess]: () => "SUCCESS",
        [getSearch]: () => "LOADING",
        [getSearchFailure]: () => "FAILURE",
        [getSearchIdle]: () => "IDLE",
    },
    'IDLE'
);

const data = handleActions(
    {
        [getSearchSuccess]: (state, action) => action.payload.data,
    },
    []
)

const search = combineReducers({
    status_search,
    data
});

export {search};
