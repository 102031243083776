import { token, domain } from "../../helpers/config";

export const getReguest = ({ route, id }) => {

    return new Promise((resolve, reject) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        // if(window.location.search && window.location.search.indexOf('admin=mode') >= 0){
        //     if(route && route.indexOf('?') >= 0){
        //         route = route + '&publicationState=preview'
        //     } else {
                route = route + '&publicationState=preview'
        //     }
        // }

        fetch(domain + route, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(id){
                    resolve({
                        [id]: result
                    })
                } else {
                    resolve(result)
                };
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}