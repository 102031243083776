import './marshrutlar.scss';

import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { domain, marshrutlar } from '../../helpers/config';
import PostLocation from '../../components/post/post_location';
import Header from '../../layout/header';

import { useDispatch, useSelector } from 'react-redux';
import { getMarshrutlar } from '../../modules/marshrutlar/actions';

const Marshrutlar = ({
    activeCity,
    setActiveCity
}) => {

    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);

    const [marshrutlar, setMarshrutlar] = useState([]);

    const dispatch = useDispatch();

    const status_marshrutlar = useSelector((state) => state.marshrutlar.status_marshrutlar);
    const state_data = useSelector((state) => state.marshrutlar.data);

    useEffect(() => {
        if(status_marshrutlar == 'IDLE'){
            dispatch(getMarshrutlar());
        }
    }, [])

    useEffect(() => {
        if(firstSwiper){
            firstSwiper.slideToLoop(activeCity);
        }
        if(secondSwiper){
            secondSwiper.slideToLoop(activeCity);
        }
    }, [firstSwiper, secondSwiper])

    useEffect(() => {
        if(status_marshrutlar == 'SUCCESS'){
            setMarshrutlar(Object.values(state_data));
        }
    }, [status_marshrutlar])

    return (
        <>
            <Header>
                <Swiper
                    // loop
                    className="marshrutlar__nav-slider"
                    slidesPerView={"auto"}
                    centeredSlides
                    onSlideChange={(e) => {
                        if(secondSwiper && secondSwiper.realIndex !== e.realIndex){
                            secondSwiper.slideToLoop(e.realIndex);

                            setActiveCity(e.realIndex);
                        }
                    }}
                    onSwiper={setFirstSwiper}
                >
                    {marshrutlar && marshrutlar.length > 0 && marshrutlar.map((marshrut, index) => {
                        return (
                            <SwiperSlide key={index}>
                                {marshrut.title} 
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Header>
            <div className='main _marshrutlar'>
                {/* <div className='marshrutlar__nav'>
                    
                </div> */}
                <Swiper
                    // loop
                    className='marshrutlar__slider'
                    slidesPerView={1}
                    onSwiper={setSecondSwiper}
                    onSlideChange={(e) => {
                        if(firstSwiper && firstSwiper.realIndex !== e.realIndex){

                            setActiveCity(e.realIndex);
                            
                            firstSwiper.slideToLoop(e.realIndex);
                        }
                    }}
                >
                    {marshrutlar && marshrutlar.length > 0 && marshrutlar.map((marshrut, marshrut_index) => {
                        return (
                            <SwiperSlide key={marshrut_index}>
                                <div className='marshrutlar__content'>
                                    {marshrut.list && marshrut.list.length > 0 && (
                                        <div className='marshrutlar__list'>
                                            {marshrut.list.map((location, location_index) => {
                                                let reverse = false;
                                                if(( location_index + 1 ) % 2 == 0){
                                                    reverse = true;
                                                }
                                                return (
                                                    <React.Fragment key={location_index}>
                                                        <PostLocation
                                                            link={'/marshrutlar/marshrut/' + location.id}
                                                            reverse={reverse}
                                                            previewUrl={location.previewUrl}
                                                            title={location.title}
                                                            isFavorite={location.isFavorite}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </>
    )
}

export default Marshrutlar;