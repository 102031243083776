import { call, put, takeEvery, all } from "redux-saga/effects";

import { getReguest } from "../homepage/api";

import {
    getLocation,
    getLocationSuccess,
    getLocationFailure,
    getLocationIdle
} from "./actions";
import { domain } from "../../helpers/config";

function* getLocationSaga(action) {
  try {

    const { payload } = action;

    const { id } = payload;

    let fields = 'fields[0]=Title'+
      '&fields[1]=Content' +
      '&populate[0]=Galleries'+
      '&populate[1]=Galleries.List'+
      '&populate[2]=Audio'+
      '&populate[3]=Coordinates'+
      '&populate[4]=Lugat'+
      '&populate[5]=City';

    let fields_locations = 'populate[0]=Coordinates';

    const [
        location,
    ] = yield all([
        call(getReguest, { route: '/api/locations/'+id+'?'+fields+'&pagination[pageSize]=1' }),
    ]);

    let data = {
        info_short: [],
        gallery: [],
        lugat: [],
        routes: [],
        list_coordinates: []
    };

    if(
      location &&
      location.data &&
      location.data.attributes
    ){
      if(
        location.data.attributes.City &&
        location.data.attributes.City.data
      ){
        const [
          locations_by_city
        ] = yield all([
          call(getReguest, { route: '/api/locations?'+fields_locations+'&filters[City][$eq]='+location.data.attributes.City.data.id+'&pagination[pageSize]=100' }),
        ]);

        let list_coordinates = [];

        locations_by_city.data.map((location) => {

          let { attributes } = location;

          let { Coordinates } = attributes;

          if(
            Coordinates &&
            Coordinates.length > 0
          ){
            Coordinates.map((item) => {

              if(item.Coordinate){
                let data_coordinate = item.Coordinate.replace(' ', '').split(',');
                list_coordinates.push({
                  lat: Number(data_coordinate[0]), 
                  lng: Number(data_coordinate[1]),
                  title: item.Title || 'Marker'
                })
              }

            })
          }

        })

        let route = {
          origin: {
            // lat: 44.7495101, 
            // lng: 33.8470202,
            // title: 'marker 1'
          },
          waypoint: [
            // {
            //   location: {
            //     lat: 44.7491951, 
            //     lng: 33.8481756,
            //     title: 'marker 2'
            //   },
            //   stopover: true
            // }
          ],
          destination: {
            // lat: 44.7474755, 
            // lng: 33.8850030,
            // title: 'marker 20'
          }
        }

        if(
          list_coordinates &&
          list_coordinates.length > 0
        ){

          let first_coordinate = list_coordinates[0];
          let range_coordinate = list_coordinates.filter((item, index) => ( index > 0 && list_coordinates.length > index + 1)).map((item) => {
            return {
              location: {
                ...item
              },
              stopover: true
            }
          });
          let last_coordinate = list_coordinates[list_coordinates.length - 1];

          route = {
            ...route,
            origin: {
              ...first_coordinate
            },
            destination: {
              ...last_coordinate
            }
          }

          if(list_coordinates.length > 2 && range_coordinate && range_coordinate.length > 0){
            route = {
              ...route,
              waypoint: [
                ...range_coordinate
              ]
            }
          }

        }

        data = {
          ...data,
          routes: [route],
          list_coordinates
        }

      }
      
      if(
        location.data.attributes.Title
      ){
        data = {
          ...data,
          title: location.data.attributes.Title
        }
      }
      if(
        location.data.attributes.Audio &&
        location.data.attributes.Audio.data &&
        location.data.attributes.Audio.data.attributes
      ){
        data = {
          ...data,
          audio: domain + location.data.attributes.Audio.data.attributes.url
        }
      }
      if(
        location.data.attributes.Content
      ){
        data = {
          ...data,
          content: location.data.attributes.Content
        }
      }
      if(
        location.data.attributes.Coordinates &&
        location.data.attributes.Coordinates.length > 0
      ){
        location.data.attributes.Coordinates.map((item) => {

          if(item.Coordinate){
            data = {
                ...data,
                info_short: [
                    ...data.info_short,
                    item.Coordinate + ' ' + (item.Title || 'Marker')
                ]
            }
          }

        })
      }
      if(
        location.data.attributes.Galleries &&
        location.data.attributes.Galleries.length > 0
      ){
        location.data.attributes.Galleries.map((gallery) => {

            let item = {
                title: gallery.Title,
                info: gallery.Info,
                gallery: [
                    // {
                    //     title: 'PostImage 1',
                    //     link: PostImage2
                    // },
                    // {
                    //     title: 'PostImage 2',
                    //     link: PostImage2
                    // },
                    // {
                    //     title: 'PostImage 3',
                    //     link: PostImage2
                    // }
                ]
            }

            let list = [];

            if(
                gallery &&
                gallery.List &&
                gallery.List.data &&
                gallery.List.data.length > 0
            ){
                gallery.List.data.map((image) => {
                    if(
                        image &&
                        image.attributes &&
                        image.attributes.url
                    ){
                        list.push(
                            {
                                title: '',
                                link: domain + image.attributes.url
                            }
                        )
                    }
                })
            }

            if(list && list.length > 0){
                item = {
                    ...item,
                    gallery: list
                }
            }

            data = {
                ...data,
                gallery: [
                    ...data.gallery,
                    item
                ]
            }
        })
      }
      if(
        location.data.attributes.Lugat &&
        location.data.attributes.Lugat.length > 0
      ){

        data = {
          ...data,
          lugat: location.data.attributes.Lugat
        }

      }
    }

    console.log(data);


    yield put(
    getLocationSuccess({
        data: data,
      })
    );

    // yield put(setActiveWarehouses(activeWarehouses))
  } catch (error) {
    console.log(error);

    yield put(getLocationFailure(error));
  }
}

export function* getLocationWatcher() {
  yield takeEvery(getLocation.toString(), getLocationSaga);
}
