import './loader.scss'

import React from 'react';

import { TailSpin } from 'react-loader-spinner'
import { useSelector } from 'react-redux';

const Loader = () => {

    const status_cities = useSelector((state) => state.homepage.status_cities);
    const status_marshrutlar = useSelector((state) => state.marshrutlar.status_marshrutlar);
    const status_city = useSelector((state) => state.city.status_city);
    const status_search = useSelector((state) => state.search.status_search);
    const status_location = useSelector((state) => state.location.status_location);

    return (
        <>
            {( status_cities == 'LOADING' || status_marshrutlar == 'LOADING' || status_city == 'LOADING' || status_search == 'LOADING' || status_location == 'LOADING') && (
                <div className='loader'>
                    <TailSpin
                        visible={true}
                        height="80"
                        width="80"
                        color="#0070BD"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
        </>
    )
}

export default Loader;