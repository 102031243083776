import 'swiper/css';
import './top_locations.css';

import React from 'react';
import PostTop from '../../components/post/post_top';

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';

const TopLocations = ({ list }) => {
    return (
        <div className='top-locations'>
            <Swiper
                spaceBetween={18}
                className="top-locations__slider"
                slidesPerView={1}
                modules={[Pagination]}
                pagination={{ clickable: true }}
            >
                {list.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className='top-locations__item'>
                                <PostTop {...item} />
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default TopLocations;