import {createActions} from "redux-actions";

const {
    getCity,
    getCitySuccess,
    getCityFailure,
    getCityIdle
} = createActions(
    "GET_CITY",
    "GET_CITY_SUCCESS",
    "GET_CITY_FAILURE",
    "GET_CITY_IDLE",
);


export {
    getCity,
    getCitySuccess,
    getCityFailure,
    getCityIdle
};
