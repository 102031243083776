import { ReactComponent as IconEr } from '../assets/images/icon-er.svg'; 
import { ReactComponent as IconHarita } from '../assets/images/icon-harita.svg'; 
import { ReactComponent as IconInfo } from '../assets/images/icon-info.svg'; 
import { ReactComponent as IconQaldirmaq } from '../assets/images/icon-qaldirmaq.svg'; 
import { ReactComponent as IconTapmaq } from '../assets/images/icon-tapmaq.svg'; 
import { ReactComponent as IconYol } from '../assets/images/icon-yol.svg'; 
import { ReactComponent as IconHeart } from '../assets/images/icon-heart.svg'; 

import PostImage1 from '../assets/images/post-1.jpg';

let token =
  "019f6f7ec27ef51cb836bf2dd9fbb1347843d0c9fc475116d0376ecda817677459995e76a47523513420c7594c5a797ad744d995e2d9366b3e402a28c702747d1a5d5bc17acc70b23ad767303edfadc1c8f53c616e1d847375236537194d026cfebddaadcb538e2663ab7e389136a7883d818a1cea330e2050cc94f82d1a0b01";
let domain = "https://yoldash-api.dev.co-dd.dev";

const navbar_menu = [
    {
        title: 'Yol',
        className: '_yol',
        link: '/',
        icon: IconYol
    },
    {
        title: 'Marshrutlar',
        className: '_marshrutlar',
        link: '/marshrutlar',
        icon: IconEr
    },
    {
        title: 'Harita',
        className: '_harita',
        link: '/harita',
        icon: IconHarita
    },
    // {
    //     title: 'Info',
    //     link: '/info',
    //     icon: IconInfo
    // },
    // {
    //     title: 'Qaldirmaq',
    //     className: '_qaldirmaq',
    //     link: '/qaldirmaq',
    //     icon: IconHeart
    // },
    {
        title: 'Tapmaq',
        className: '_tapmaq',
        link: '/tapmaq',
        icon: IconTapmaq
    }
];

const marshrutlar = [
    {
        id: 1,
        title: 'Eski Qırım',
        list: [
            {
                id: 1,
                title: 'Uzbek-han camisi',
                previewUrl: PostImage1,
                isFavorite: false
            },
            {
                id: 2,
                title: 'İncibek-hatun medresesi',
                previewUrl: PostImage1,
                isFavorite: true
            },
            {
                id: 3,
                title: 'Uzbek-han camisi',
                previewUrl: PostImage1,
                isFavorite: false
            },
            {
                id: 4,
                title: 'İncibek-hatun medresesi',
                previewUrl: PostImage1,
                isFavorite: true
            },
            {
                id: 5,
                title: 'Uzbek-han camisi',
                previewUrl: PostImage1,
                isFavorite: false
            },
            {
                id: 6,
                title: 'İncibek-hatun medresesi',
                previewUrl: PostImage1,
                isFavorite: true
            }
        ]
    },{
        id: 2,
        title: 'Qarasubazar',
        list: [
            {
                id: 3,
                title: 'Uzbek-han camisi',
                previewUrl: PostImage1,
                isFavorite: false
            }
        ]
    },{
        id: 3,
        title: 'Eski Qırım',
        list: [
            {
                id: 4,
                title: 'Uzbek-han camisi',
                previewUrl: PostImage1,
                isFavorite: false
            },
            {
                id: 5,
                title: 'İncibek-hatun medresesi',
                previewUrl: PostImage1,
                isFavorite: true
            }
        ]
    },{
        id: 4,
        title: 'Qarasubazar',
        list: [
            {
                id: 6,
                title: 'Uzbek-han camisi',
                previewUrl: PostImage1,
                isFavorite: true
            }
        ]
    },{
        id: 5,
        title: 'Eski Qırım',
        list: [
            {
                id: 7,
                title: 'Uzbek-han camisi',
                previewUrl: PostImage1,
                isFavorite: true
            },
            {
                id: 8,
                title: 'İncibek-hatun medresesi',
                previewUrl: PostImage1,
                isFavorite: false
            }
        ]
    },{
        id: 6,
        title: 'Qarasubazar',
        list: [
            {
                id: 9,
                title: 'Uzbek-han camisi',
                previewUrl: PostImage1,
                isFavorite: false
            }
        ]
    }
]

export { navbar_menu, marshrutlar, domain, token }