import {createActions} from "redux-actions";

const {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle
} = createActions(
    "GET_SEARCH",
    "GET_SEARCH_SUCCESS",
    "GET_SEARCH_FAILURE",
    "GET_SEARCH_IDLE",
);


export {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle
};
