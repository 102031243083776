import './App.css';
import './index.scss';

import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './layout/navbar';

import Home from './pages/home';
import Harita from './pages/harita';
import Erier from './pages/erier';
import Marshrutlar from './pages/marshrutlar';
import Location from './pages/location';
import Tapmaq from './pages/tapmaq';
import Qaldirmaq from './pages/qaldirmaq';
import Loader from './sections/loader/loader';

function App() {

  const [isLoad, setIsLoad] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [activeCity, setActiveCity] = useState(0);

  useEffect(() => {
    const state_first = localStorage.getItem('firstView');

    if (!state_first) {
      setIsFirstLoad(true);
      localStorage.setItem('firstView', 'true');
    }
  }, []);

  console.log(activeCity);

  return (
    <Router>
      <div className='app'>
        <Loader />
        <Routes>
          <Route path={'/'} element={<Home isLoad={isLoad} setIsLoad={setIsLoad} isFirstLoad={isFirstLoad} />}  />
          <Route path={'/harita'} element={<Harita />}  />
          <Route path={'/marshrutlar'} element={<Marshrutlar activeCity={activeCity} setActiveCity={setActiveCity} />}  />
          <Route path={'/marshrutlar/:cityID'} element={<Erier />}  />
          <Route path={'/marshrutlar/:marshrut/:locationID'} element={<Location activeCity={activeCity} setActiveCity={setActiveCity} />}  />
          <Route path={'/tapmaq'} element={<Tapmaq />}  />
          <Route path={'/qaldirmaq'} element={<Qaldirmaq />}  />
        </Routes>
        <Navbar />
      </div>
    </Router>
  );
}

export default App;
