import React from 'react';

const Node = (props) => {
    // console.log(props);
    return (
        <>
            {props.type == 'text' && <>{props.text}</>}
            {props.type == 'heading' && (
                <>
                    {props.level == 2 && (
                        <h2
                            dangerouslySetInnerHTML={{
                                __html: props.children[0].text,
                            }}
                        ></h2>
                    )}
                    {props.level == '2_3' && (
                        <h2
                            className="_h3"
                            dangerouslySetInnerHTML={{
                                __html: props.children[0].text,
                            }}
                        ></h2>
                    )}
                    {props.level == 3 && (
                        <h3
                            dangerouslySetInnerHTML={{
                                __html: props.children[0].text,
                            }}
                        ></h3>
                    )}
                    {props.level == 4 && (
                        <h4
                            dangerouslySetInnerHTML={{
                                __html: props.children[0].text,
                            }}
                        ></h4>
                    )}
                </>
            )}
            {props.type == 'paragraph' && (
                <p>
                    {props.children.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {(item.type == 'link' && (
                                    <a
                                        href={item.url}
                                        target={
                                            (typeof window !== 'undefined' &&
                                                item.url.indexOf(
                                                    window.location.host
                                                ) < 0) ||
                                            item.url.indexOf('normdubai.com') <
                                                0
                                                ? '_blank'
                                                : ''
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: item.children[0].text,
                                        }}
                                    ></a>
                                )) ||
                                    (item.bold && (
                                        <b
                                            dangerouslySetInnerHTML={{
                                                __html: item.text,
                                            }}
                                        ></b>
                                    )) || (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: item.text,
                                            }}
                                        ></span>
                                    )}
                            </React.Fragment>
                        );
                    })}
                </p>
            )}
            {props.type == 'image' && (
                <figure
                    className={
                        (props.image.caption == '' && '_none-caption') || ''
                    }
                >
                    <img src={props.image.url} />
                    <figcaption>{props.image.caption}</figcaption>
                    {props.image.alternativeText.indexOf('.png') < 0 &&
                        props.image.alternativeText.indexOf('.jpg') < 0 && (
                            <span className="figure-credit">
                                {props.image.alternativeText}
                            </span>
                        )}
                </figure>
            )}
            {props.type == 'list' && (
                <>
                    {props.format == 'unordered' && (
                        <ul>
                            {props.children &&
                                props.children.length > 0 &&
                                props.children.map((listItem) => {
                                    return (
                                        <li>
                                            {listItem.children &&
                                                listItem.children.length > 0 &&
                                                listItem.children.map(
                                                    (node, index) => {
                                                        return (
                                                            <Node
                                                                key={index}
                                                                {...node}
                                                            />
                                                        );
                                                    }
                                                )}
                                        </li>
                                    );
                                })}
                        </ul>
                    )}
                    {props.format == 'ordered' && (
                        <ol>
                            {props.children &&
                                props.children.length > 0 &&
                                props.children.map((listItem) => {
                                    return (
                                        <li>
                                            {listItem.children &&
                                                listItem.children.length > 0 &&
                                                listItem.children.map(
                                                    (node, index) => {
                                                        return (
                                                            <Node
                                                                key={index}
                                                                {...node}
                                                            />
                                                        );
                                                    }
                                                )}
                                        </li>
                                    );
                                })}
                        </ol>
                    )}
                </>
            )}
        </>
    );
};

export default Node;
