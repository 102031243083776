import './post.scss';

import React from "react";

import PostImage1 from '../../assets/images/post-1.jpg';
import { Link } from 'react-router-dom';

const PostTop = ({
    id = '',
    slug = '',
    preview = '',
    title = '',
    info = ''
}) => {
    return (
        <Link to={`/marshrutlar/${id}`} className='post _top'>
            <div className='post__bg'>
                <img src={preview} alt="ALT" />
            </div>
            <div className='post__wrap'>
                <div className='post__title'>{title}</div>
                <div className='post__info'>{info}</div>
            </div>
        </Link>
    )
}

export default PostTop;