import { combineReducers } from "redux";
import { homepage } from "./modules/homepage/reducer";
import { marshrutlar } from "./modules/marshrutlar/reducer";
import { city } from "./modules/erier/reducer";
import { harita } from "./modules/harita/reducer";
import { location } from "./modules/location/reducer";
import { search } from "./modules/search/reducer";

export default combineReducers({
  homepage,
  marshrutlar,
  city,
  harita,
  location,
  search
});
